.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 98vh;
  min-width: 98vw;
  align-items: center;
  justify-content: center;
  font-size: calc(10vmin);
  color: white;
  margin: auto;
  padding-left: 1vw;
  padding-top: 1vh;
  padding-right: 1vw;
  padding-bottom: 1vh;
}

.App-textarea {
  font-family: inherit;
  font-size: inherit;
  background-color: #282c34;
  align-items: center;
  justify-content: center;
  font-size: calc(10vmin);
	text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  min-width: 98vw;
  color: white;
  margin: auto;
  padding-left: 1vw;
  padding-top: 1vh;
  padding-right: 1vw;
  padding-bottom: 1vh;
}
